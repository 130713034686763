import * as React from 'react';
import HomePage from './home/index';

type BodyPropsType = {
  viewRef: (node?: Element | null | undefined) => void;
};

const IndexPage = ({ viewRef }: BodyPropsType) => {
  return <HomePage viewRef={viewRef} />;
};

export default IndexPage;
